/* eslint-disable no-console */
/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
// import ContentfulData from "components/ContentfulData";
// import LanguageSelect from "components/TranslateComponent";
// import TranslateComponent from "components/TranslateComponent";
import React, { useEffect, useRef, useState } from "react";

// import { Advantages } from "const/landingPageData";
import Header from "parts/Header";
import Hero from "parts/Hero";
import Service from "parts/Service";
// import Portfolio from "parts/Portfolio";
// import Advantage from "parts/Advantage";
import Testimonial from "parts/Testimonial";
// import Discuss from "parts/Discuss";
// import Footer from "parts/Footer";
import client from "api/client";
import AboutMe from "parts/AboutMe";
// import HRBusinessPartner from "parts/HrBusinessPartner";
// import MediationSection from "parts/MedationSection";
import ContactUs from "parts/Contactus";
// import Recurative from "parts/Recurative";
import ImageSwiper from "parts/ImageSwiper";
import Footer from "parts/Footer";
// import CoachingSection from "parts/Coaching";
// import MySwiper from "../parts/MySwiper";

const LandingPage = () => {
  const contactUsRef = useRef(null);
  const serviceRef = useRef(null);
  const aboutMeRef = useRef(null);

  const handleScrollToContactUs = () => {
    contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const onServiceClick = () => {
    serviceRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const onClickAboutMe = () => {
    aboutMeRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessLandingPage",
      })
      .then((response) => {
        setData(response?.items[0]?.fields);
      })

      .catch(console.error);
  }, []);

  return (
    <>
      <Header
        onServiceClick={onServiceClick}
        onClickAboutMe={onClickAboutMe}
        handleScrollToContactUs={handleScrollToContactUs}
      />
      <Hero data={data} handleScrollToContactUs={handleScrollToContactUs} />
      <ImageSwiper />
      <AboutMe ref={aboutMeRef} />
      <Service ref={serviceRef} />
      <Testimonial />
      {/* <HRBusinessPartner />
      <MediationSection />
      <CoachingSection />
      <Recurative /> */}
      <ContactUs ref={contactUsRef} />
      <Footer />
    </>
  );
};

export default LandingPage;
