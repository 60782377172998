import React, { useContext, useEffect, useState } from "react";
import client from "api/client";
import { LanguageContext } from "context/LanguageContext";
import Mediation from "../assets/images/Menu pic Mediation & Conflict Management .jpg";

export default function MediationSection() {
  const { language } = useContext(LanguageContext); // Accessing the current language
  const [data, setData] = useState({});

  useEffect(() => {
    client
      .getEntries({
        content_type: "meditationConflictManagement",
      })
      .then((response) => {
        const fields = response?.items[0]?.fields;
        // Transform the API response into the desired structure
        const transformedData = {
          en: {
            title: fields?.enTitle || "",
            description: fields?.fiDes?.split("\n\n")[0] || "",
            process: fields?.fiDes?.split("\n\n")[1] || "",
            steps: fields?.fiDes?.split("\n\n")[2]?.split(" > ") || [],
            additionalInfo: fields?.fiDes?.split("\n\n")[3] || "",
            buttonText: "Read More",
          },
          fi: {
            title: fields?.fiTitle || "",
            description: fields?.enDes?.split("\n\n")[0] || "",
            process: fields?.enDes?.split("\n\n")[1] || "",
            steps: fields?.enDes?.split("\n\n")[2]?.split(" > ") || [],
            additionalInfo: fields?.enDes?.split("\n\n")[3] || "",
            buttonText: "Lue lisää",
          },
        };
  
        setData(transformedData);
      })
      .catch(console.error);
  }, []);

  return (
    <section className="container max-w-7xl mx-auto py-16 px-4 lg:px-8 flex flex-col md:flex-row items-start gap-10">
      {/* Left Column: Images */}
      <div className="w-full md:w-1/2 flex justify-center md:justify-end">
        <div className="relative w-full md:w-[500px] h-[300px] md:h-[600px]">
          <img
            src={Mediation}
            alt="Mediation and Conflict Management"
            className="w-full h-full object-cover rounded-lg shadow-md"
          />
        </div>
      </div>

      {/* Right Column: Text Content */}
      <div className="w-full md:w-1/2 md:pl-8">
        <h2 className="text-3xl md:text-4xl font-bold text-black mb-6">
        {data[language]?.title}
        </h2>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed">
        {data[language]?.description}
        </p>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed">
          {data[language]?.process}
        </p>
        {/* Process Steps */}
        <p className="text-lg text-gray-700 mb-8 leading-relaxed">
          {data[language]?.steps.join(" > ")}
        </p>
        <p className="text-lg text-gray-700 mb-8 leading-relaxed">
          {data[language]?.additionalInfo}
        </p>
      </div>
    </section>
  );
}
