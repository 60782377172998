/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
import React, { useContext } from "react";

// import Footer from "parts/Footer";
import Header from "parts/Header";
import { LanguageContext } from "context/LanguageContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const translations = {
  en: {
    title: "Contact Information",
    address: "9478 W Marquette Ln, Crystal River, FL 34428, United States",
    email: "inka.rautiala@imrconsulting.fi",
    phone: "+1 123-1234-0000",
  },
  fi: {
    title: "Yhteystiedot",
    address: "9478 W Marquette Ln, Crystal River, FL 34428, Yhdysvallat",
    email: "inka.rautiala@imrconsulting.fi",
    phone: "+1 123-1234-0000",
  },
};

const ContactUs = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const sendEmail = (e) => {
    e.preventDefault();

    // Get form values
    const { firstName, lastName, email, message } = e.target;

    // Validate fields
    if (!firstName.value || !lastName.value || !email.value || !message.value) {
      toast.error("Please fill in all required fields.", {
        className: "black-background",
        bodyClassName: "white-text",
      });
      return; // Stop the function if validation fails
    }

    // Create mailto link
    const subject = encodeURIComponent(
      `Message from ${firstName.value} ${lastName.value}`
    );
    const phoneValue = e.target.phone.value;
    const body = encodeURIComponent(
      `${
        `Name: ${firstName.value} ${lastName.value}\n` +
        `Email: ${email.value}\n`
      }${phoneValue ? `Phone: ${phoneValue}\n` : ""}\nMessage:\n${
        message.value
      }`
    );

    const mailtoLink = `mailto:${t.email}?subject=${subject}&body=${body}`;

    // Open mail client
    window.location.href = mailtoLink;
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-8 py-16">
        <div className="flex flex-col h-[667px] md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
          {/* Contact Info Section */}
          {/* <div className="bg-[#212529] text-white p-8 md:w-1/2">
            <h2 className="text-2xl pb-5 font-bold mb-6">{t.title}</h2>
            <div className="mb-4 py-8 flex items-center">
              <FaPhone className="mr-3 text-lg" />
              <p>{t.phone}</p>
            </div>
            <div className="mb-4 py-8 flex items-center">
              <FaEnvelope className="mr-3 text-lg" />
              <p>{t.email}</p>
            </div>
            <div className="mb-8 py-8 flex items-center">
              <FaMapMarkerAlt className="mr-3 text-lg" />
              <p>{t.address}</p>
            </div>
            <div className="flex mt-52 space-x-4">
              <a href="#" className="text-white hover:text-gray-300">
                <FaTwitter size={24} />
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <FaInstagram size={24} />
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <FaFacebook size={24} />
              </a>
            </div>
          </div> */}
          {/* Form Section */}
          <div className="p-8 md:w-1/2 bg-white">
            <form onSubmit={sendEmail}>
              <div className="grid grid-cols-2 gap-4 mb-10">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
                />
              </div>
              <div className="grid grid-cols-2 py-8 gap-4 mb-10">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
                />
              </div>
              <textarea
                name="message"
                placeholder="Write your message.."
                className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black mb-4"
                rows="4"
              />
              <div className="flex mt-44 justify-end">
                <button
                  type="submit"
                  className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition duration-300"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <ToastContainer />
      <style jsx global>{`
        .black-background {
          background-color: black !important;
          border-color: white !important;
        }
        .white-text {
          color: white !important;
        }
      `}</style>
    </>
  );
};

export default ContactUs;
