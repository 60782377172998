/* eslint-disable no-console */
/* eslint-disable import/no-duplicates */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLinkedin,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forwardRef } from "react";
import client from "api/client";
import { LanguageContext } from "context/LanguageContext";

const translations = {
  en: {
    title: "Contact Information",
  },
  fi: {
    title: "Yhteystiedot",
  },
};

const ContactUs = forwardRef((props, ref) => {
  const formRef = useRef(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "contactInformation",
      })
      .then((response) => {
        setData(response?.items[0]?.fields);
      })
      .catch(console.error);
  }, []);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const sendEmail = (e) => {
    e.preventDefault();

    const { firstName, lastName, email, phone, message } = e.target;

    // Validate fields
    if (
      !firstName.value.trim() ||
      !lastName.value.trim() ||
      !email.value.trim() ||
      !message.value.trim()
    ) {
      toast.error("Please fill in all required fields.", {
        className: "black-background",
        bodyClassName: "white-text",
      });
      return;
    }

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email.value)) {
      toast.error("Please enter a valid email address.", {
        className: "black-background",
        bodyClassName: "white-text",
      });
      return;
    }

    // Validate phone number (if provided)
    if (phone.value.trim() && !/^[0-9-+\s()]*$/.test(phone.value)) {
      toast.error("Please enter a valid phone number.", {
        className: "black-background",
        bodyClassName: "white-text",
      });
      return;
    }

    // Create mailto link
    const subject = encodeURIComponent(
      `Message from ${firstName.value} ${lastName.value}`
    );
    const body = encodeURIComponent(
      `${
        `Name: ${firstName.value} ${lastName.value}\n` +
        `Email: ${email.value}\n`
      }${phone.value ? `Phone: ${phone.value}\n` : ""}Message:\n${
        message.value
      }`
    );
    const mailtoLink = `mailto:${data?.email}?subject=${subject}&body=${body}`;

    // Open the mail client
    window.location.href = mailtoLink;

    // Reset the form
    formRef.current.reset();
  };

  return (
    <div ref={ref} className="container mx-auto px-4 md:px-8 py-16">
      <div className="flex flex-col lg:flex-row-reverse h-auto lg:h-[667px] bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Form Section */}
        <div className="p-8 lg:w-1/2 bg-white">
          <form ref={formRef} onSubmit={sendEmail}>
            <div className="grid lg:py-8 grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
              />
            </div>
            <div className="grid lg:py-8 grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number (Optional)"
                className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black"
              />
            </div>
            <textarea
              name="message"
              placeholder="Write your message.."
              className="border-b border-gray-400 p-2 w-full focus:outline-none focus:border-black mb-4"
              rows="4"
            ></textarea>
            <div className="flex justify-end mt-8 lg:mt-44">
              <button
                type="submit"
                className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition duration-300"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>

        {/* Contact Info Section */}
        <div className="bg-[#212529] lg:py- text-white p-8 lg:w-1/2">
          <h2 className="text-2xl lg:text-3xl pb-5 font-bold mb-6">
            {t?.title}
          </h2>
          <div className="mb-4 lg:py-8 flex items-center space-x-3">
            <FaPhone className="text-lg lg:text-2xl" />
            <p>{data?.phoneNo}</p>
          </div>
          <div className="mb-4 flex lg:py-8 items-center space-x-3">
            <FaEnvelope className="text-lg lg:text-2xl" />
            <p>{data?.email}</p>
          </div>
          <div className="mb-8 flex lg:py-8 items-center space-x-3">
            <FaMapMarkerAlt className="text-lg lg:text-2xl" />
            <p>{data?.location}</p>
          </div>
          <div className="flex space-x-4 mt-10 lg:mt-48">
            <a
              href={data?.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-300"
            >
              <FaLinkedin size={24} />
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
      <style jsx global>{`
        .black-background {
          background-color: black !important;
          border-color: white !important;
        }
        .white-text {
          color: white !important;
        }
      `}</style>
    </div>
  );
});

export default ContactUs;
