/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import client from "api/client";
import { EffectCards, Navigation, Pagination, Autoplay } from "swiper/modules";

const ImageSwiper = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessHeroPortfolio",
      })
      .then((response) => {
        setData(response?.items[0]?.fields?.carousel);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="py-20">
      <div className="image-swiper-container px-4 sm:px-14 md:px-40">
        <Swiper
          effect="cards"
          grabCursor
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          modules={[EffectCards, Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          className="mySwiper bg-white shadow-lg rounded-lg"
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="image-container">
                <img
                  src={item.fields.file.url}
                  alt={`Slide ${index + 1}`}
                  className="slide-image w-full h-auto object-cover transition-transform duration-300 ease-in-out transform hover:scale-110 shadow-md rounded-lg"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Custom Styles for Navigation and Pagination */}
      <style>
        {`
          .swiper {
            background-color: white;
            border-radius: 10px;
          }
          .swiper-button-next,
          .swiper-button-prev {
            color: black;
            background:#ffff;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
          .swiper-button-next:hover,
          .swiper-button-prev:hover {
            background: #ffff;
          }
          .swiper-pagination-bullet {
            background: black;
            opacity: 0.7;
          }
          .swiper-pagination-bullet-active {
            background: #ffff; /* Highlight color */
          }
        `}
      </style>
    </div>
  );
};

export default ImageSwiper;
